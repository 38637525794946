const FrontCheckList = {
    SendNotice: function (selected_component) {
        var temp_obj;
        switch (selected_component) {
            case "SendNoticeAll":
            case "SendNoticeOwnCoupon":
                {
                    temp_obj = {
                        header: ["유형",
                            "이름",
                            "학교",
                            "학년",
                            "최근결제일",
                            "만기경과",
                            "셔틀버스",
                            "학생",
                            "학부모",],
                        size: {
                            // size: [80, 100, 80, 60, 100, 80, 80, 50, 50],
                            grow: 1,
                            blueBoldLineColumn: [1],
                            checkboxColumn: [7, 8],
                            popup: [1],
                        },
                    }
                }
                break;
            case "SendNoticeDischarged":
                {
                    temp_obj = {
                        header: ["이름", "학교", "학년", "퇴원일", "관리도", "학부모"],
                        size: {
                            grow: 0,
                            blueBoldLineColumn: [0],
                            checkboxColumn: [5],
                        },
                    }
                }
                break;
            case "SendNoticePaid":
                {
                    temp_obj = {
                        header: ["이름",
                            "학교",
                            "학년",
                            "결제일",
                            "결제유형",
                            "학생",
                            "학부모",],
                        size: {
                            grow:0,
                            blueBoldLineColumn: [0],
                            checkboxColumn: [5, 6],
                        },
                    }
                }
                break;
            case "FrontSendNoticePayWait":
                {
                    temp_obj = {
                        header: ["이름",
                            "학교",
                            "학년",
                            "결제일",
                            "관리도",
                            "학생",
                            "학부모",],
                        size: {
                            grow:0,
                            blueBoldLineColumn: [0],
                            checkboxColumn: [5, 6],
                        },
                    }
                }
                break;
            case "SendNoticeAsk":
                {
                    temp_obj = {
                        header: ["이름", "학교", "학년", "관리도", "학생", "학부모"],
                        size: {
                            grow:0,
                            blueBoldLineColumn: [0],
                            checkboxColumn: [4, 5],
                        },
                    }
                }
                break;
            default:
                break;
        }
        return temp_obj;
    },
}

export default FrontCheckList