// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../node_modules/vue-loader/dist/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!./assets/css/main.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../node_modules/vue-loader/dist/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!./assets/css/consulting/input.reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div#searcharea {\n  height: 30px;\n  display: flex;\n  background: #f2f2f2;\n  justify-content: center;\n  align-items: center;\n  font-size: 13px;\n  border-radius: 20px;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  padding-left: 20px;\n  margin-left: auto;\n  margin-right: 0;\n}\ndiv#searcharea > div.searchbox {\n  display: flex;\n  margin-left: 10px;\n}\ndiv#searcharea > div.searchbox > input {\n  width: 100px;\n  background: inherit;\n}\ndiv#searcharea > div.searchbox > input::-moz-placeholder {\n  font-size: 14px;\n}\ndiv#searcharea > div.searchbox > input::placeholder {\n  font-size: 14px;\n}\ndiv#searcharea > div.searchbox > button {\n  margin-left: 10px;\n  background: var(--color-sparta);\n  color: white;\n  border-radius: 20px;\n  font-size: 18px;\n  height: 20px;\n  width: 20px;\n  line-height: 15px;\n}\ndiv#searcharea > div.searchbox > button > i {\n  font-size: 15px;\n}\ndiv#searcharea > div.searchbox > input {\n  border: none;\n  outline: none;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
